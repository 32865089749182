import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { compose } from "redux";
import moment from "moment";
import lodash, { isNil, find, template, isEmpty } from "lodash";
import classNames from "classnames";
import gql from "graphql-tag";
import MobileStepper from "@material-ui/core/MobileStepper";
import {
  Divider,
  Badge,
  Icon,
  IconButton,
  InputBase,
  Fab,
  Menu,
  MenuItem,
  CardHeader,
  ListItemIcon,
  Stepper,
  Step,
  StepButton,
  Tabs,
  Tab,
  Tooltip,
} from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CancelIcon from "@material-ui/icons/Cancel";
import Assessment from "./Assessment";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { withApi } from "../../api/ApiProvider";
import ReactoryApi from "../../api/ReactoryApi";
import getInstructions from "./defaultInstructions";
import { defaultCommentLabels, defaultFeelingRatingLabels } from "./defaultInstructions";
const nil = isNil;

class RatingControl extends Component<any, any> {
  static styles = (theme) => {
    const primaryColor = theme.palette.primary.main;
    const primaryColorLight = theme.palette.primary.light;
    return {
      behaviourTitle: {
        color: primaryColor,
        textAlign: "center",
        fontSize: "20px",
        marginLeft: "10px",
        marginRight: "10px",
        marginBottom: "10px"
      },

      behaviourSubTitle: {
        color: theme.palette.secondary.main,
        textAlign: "center",
        fontSize: "20px",
        marginLeft: "10px",
        marginRight: "10px",
      },

      textField: {
        backgroundColor: "unset",
      },
      textFieldGood: {},
      textFieldWarn: {
        backgroundColor: primaryColorLight,
      },
      textFieldDanger: {},
      ratingContainer: {
        paddingLeft: "5px",
        paddingRight: "5px",
        marginBottom: "16px",
        marginTop: "5px",
      },
      behaviourSelection: {
        fontSize: "18px",
        color: primaryColorLight,
        paddingBottom: "5px",
        textAlign: "center",
      },
      confirmDeleteContainer: {
        marginTop: "16px",
        padding: "16px",
      },
    };
  };

  minWordCount: number = 10;

  constructor(props, context) {
    super(props, context);
    this.ratingClick = this.ratingClick.bind(this);
    this.commentChanged = this.commentChanged.bind(this);
    this.notifyChange = this.notifyChange.bind(this);
    this.confirmCustomDelete = this.confirmCustomDelete.bind(this);
    this.state = {
      comment: props.comment || "",
    };
    this.minWordCount = 10;
  }

  ratingClick(score) {
    const { behaviour, rating } = this.props;

    const data = {
      behaviour,
      rating,
      score,
    };
    this.props.onRatingChange(data);
  }

  notifyChange() {
    const { behaviour, rating } = this.props;
    const data = {
      behaviour,
      rating,
      comment: this.state.comment,
      persist: true,
    };

    this.props.onCommentChange(data);
  }

  commentChanged(evt) {
    const { behaviour, rating } = this.props;
    const that = this;
    const data = {
      behaviour,
      rating,
      comment: evt.target.value,
      persist: false,
    };

    this.setState({ comment: data.comment }, () => {
      that.props.onCommentChange(data);
    });
  }

  confirmCustomDelete() {
    this.setState({ confirmDelete: true });
  }

  render() {
    const { behaviour, classes, rating, assessment, theme } = this.props;
    const that = this;
    let steps = [];
    for (
      let stepId = behaviour.scale.min;
      stepId < behaviour.scale.max;
      stepId++
    ) {
      const doRatingClick = (evt) => {
        evt.stopPropagation();
        that.ratingClick(stepId);
      };

      steps.push(
        <Step key={stepId}>
          <StepButton
            onClick={assessment.complete === false ? doRatingClick : () => {}}
            completed={false}
            active={rating.rating - 1 === stepId}
          ></StepButton>
        </Step>
      );
    }

    let commentAllowed = true;
    let is180 = assessment.survey.surveyType.endsWith("180");
    switch (assessment.survey.surveyType) {
      case "culture":
      case "i360":
      case "l360":
      case "team180": {
        commentAllowed = false;
      }
    }

    let commentControl = null;
    //if ((rating.rating > 0 && rating.rating < 3 || behaviour.custom === true)) {
    //const controlClasses = classNames( this.state.comment.split(' ').length < 10 ? classes.)
    const hasError =
      this.state.comment.split(" ").length < that.minWordCount &&
      rating.rating <= 2;
    const wordCount = this.state.comment.split(" ").length;
    let wordsLeft = "";

    if (wordCount === 0) {
      wordsLeft = ` (at least 10 words ${
        rating.rating <= 2 ? "required!" : "optional"
      })`;
    }

    if (
      wordCount > 0 &&
      wordCount < this.minWordCount &&
      this.state.comment.length > 1
    ) {
      wordsLeft = ` (${this.minWordCount - wordCount} words left)`;
    }

    commentControl = (
      <TextField
        id="multiline-flexible"
        label={
          this.state.comment.split(" ").length < that.minWordCount &&
          rating.rating <= 2
            ? "How does this impact you? - * required"
            : "How does this impact you?"
        }
        multiline
        fullWidth
        rowsMax="4"
        variant="outlined"
        error={hasError}
        value={this.state.comment}
        onChange={
          assessment.complete === false ? this.commentChanged : () => {}
        }
        onBlur={assessment.complete === false ? this.notifyChange : () => {}}
        autoFocus={this.state.comment.split(" ").length < 10}
        className={classes.textField}
        disabled={assessment.complete === true}
        margin="normal"
        helperText={`Provide some ${
          rating.rating <= 2 ? "required" : "optional"
        } context as to how this affects you personally or your ability to perform your duties${wordsLeft}.`}
      />
    );

    let selectedLabel = find(behaviour.scale.entries, (entry) => {
      return entry.rating === rating.rating;
    }) || { description: "Make a selection" };

    let ratingTooltip =
      rating.rating === 0 ? (
        <Tooltip
          title={`Behaviour ${
            that.props.reactory.hasRole(["DEVELOPER"]) === true
              ? `(${rating.id})`
              : ""
          } Requires a rating selection`}
        >
          <Icon color="error">info</Icon>
        </Tooltip>
      ) : (
        <Tooltip title={`You provide a score of ${rating.rating}`}>
          <Icon style={{ color: theme.palette.success.main }}>
            check_circle
          </Icon>
        </Tooltip>
      );

    if (commentAllowed === true && rating.rating > 0 && rating.rating <= 2) {
      if (this.state.comment.length < 50) {
        ratingTooltip = (
          <Tooltip title="You have given a score lower than 3 which requires you to provide some further input that is longer than 50 characters in length">
            <Icon color="secondary">info</Icon>
          </Tooltip>
        );
      }
    }

    let $ratingContent = "processing";
    let $ratingSubContent = "processing";

    let isSelfAssessment = assessment.selfAssessment === true;

    if (is180 === true && assessment.team === "delegates") {
      isSelfAssessment = true;
    }

    if (is180 === true && assessment.team === "assessor") {
      isSelfAssessment = false;
    }

    try {
      let $title = behaviour.title;
      if (isSelfAssessment === true && behaviour.delegateTitle) {
        $title = behaviour.delegateTitle;
      } else {
        if (behaviour.assessorTitle) {
          $title = behaviour.assessorTitle;
        }
      }
      $ratingContent = template($title)({
        employee: assessment.delegate,
        employeeDemographics: assessment.delegate.demographics || {
          pronoun: "his/her",
        },
        assessment,
        survey: assessment.survey,
        api: this.props.api,
      });
    } catch (templateErr) {
      that.props.api.log(
        `Behaviour Template Error`,
        { template: behaviour.title, templateErr },
        "error"
      );

      $ratingContent = `Error Processing behaviour template text. See logs for details`;
    }

    try {
      let $description = behaviour.description;
      if (isSelfAssessment === true && behaviour.delegateDescription) {
        $description = behaviour.delegateDescription;
      } else {
        if (behaviour.assessorDescription) {
          $description = behaviour.assessorDescription;
        }
      }
      $ratingSubContent = template($description)({
        employee: assessment.delegate,
        employeeDemographics: assessment.delegate.demographics || {
          pronoun: "his/her",
        },
        assessment,
        survey: assessment.survey,
        api: this.props.api,
      });
    } catch (e) {
      that.props.api.log(
        `Behaviour Template Error`,
        { template: behaviour.description, templateErr: e },
        "error"
      );

      $ratingContent = `Error Processing behaviour template text. See logs for details`;
    }

    const contentsDiffer = $ratingContent !== $ratingSubContent;

    const ratingComponent = (
      <div id="rating-paper">
        <Badge>{ratingTooltip}</Badge>
        <Typography variant="body1" className={classes.behaviourTitle}>
          {$ratingContent}
        </Typography>
        {contentsDiffer && (
          <Typography variant="body2" className={classes.behaviourSubTitle}>
            {$ratingSubContent}
          </Typography>
        )}

        <Stepper alternativeLabel nonLinear activeStep={rating.rating - 1}>
          {steps}
        </Stepper>
        <p className={`${classes.behaviourSelection}`}>
          {selectedLabel.description}
        </p>
        {commentAllowed == true && commentControl}
        {this.props.rating.custom === true ? (
          <Tooltip title="Click to delete this custom behaviour">
            <IconButton onClick={that.confirmCustomDelete}>
              <Icon>delete</Icon>
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    );

    const onDelete = () => {
      if (lodash.isFunction(this.props.onDelete))
        this.props.onDelete(this.props.rating);
    };

    const cancelDelete = () => {
      that.setState({ confirmDelete: false });
    };

    const confirmComponent = (
      <Fragment>
        <div className={this.props.classes.confirmDeleteContainer}>
          <Typography style={{ margin: "0 16px 0 8px" }}>
            Are you sure you want to delete this custom behaviour?
          </Typography>
          <Fab color="primary" onClick={onDelete}>
            <Icon>check</Icon>
          </Fab>
          <Button onClick={cancelDelete}>CANCEL</Button>
        </div>
      </Fragment>
    );

    return (
      <Grid item sm={12} xs={12} >
        <Paper className={classes.ratingContainer}>
          {this.state.confirmDelete === true
            ? confirmComponent
            : ratingComponent}
        </Paper>
      </Grid>
    );
  }

  static propTypes = {
    behaviour: PropTypes.object,
    api: PropTypes.instanceOf(ReactoryApi),
    rating: PropTypes.object,
    comment: PropTypes.string,
    onRatingChange: PropTypes.func,
    onCommentChange: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    behaviour: {},
    rating: {
      id: null,
      rating: 0,
    },
    comment: "",
    onRatingChange: () => {},
    onCommentChange: () => {},
    onDelete: () => {},
  };
}

export const RatingComponent = compose(
  withApi,
  withTheme,
  //@ts-ignore
  withStyles(RatingControl.styles)
)(RatingControl);

class DefaultView extends Component<any, any> {
  // #region style definition
  static styles = (theme) => {
    const primaryColor = theme.palette.primary.main;
    const primaryColorLight = theme.palette.primary.light;

    const windowWidth = window.innerWidth;

    return {
      root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      },
      input: {
        marginLeft: 8,
        flex: 1,
      },
      iconButton: {
        padding: 10,
      },
      divider: {
        width: 1,
        height: 28,
        margin: 4,
      },
      card: {
        width: "100%",
        maxWidth: "1024px",
        marginLeft: "auto",
        marginRight: "auto",
        color: primaryColorLight,
      },
      media: {
        maxWidth: 375,
      },
      button: {
        float: "right",
      },
      statsCard: {
        minHeight: "150px",
        marginLeft: "auto",
        marginRight: "auto",
      },
      welcomeHeader: {
        padding: 0,
        margin: 0,
        textAlign: "center",
        paddingTop: "5px",
        color: primaryColor,
      },
      statsScoreLabel: {
        position: "relative",
        bottom: "70px",
        width: "100%",
        textAlign: "center",
        display: "block",
        color: primaryColor,
        fontWeight: "bold",
      },
      statsCardAlt: {
        backgroundColor: primaryColorLight,
        minHeight: "150px",
      },
      logo: {
        maxWidth: "370px",
        height: windowWidth < 768 ? "90px" : "110px",
        marginLeft: "auto",
        marginRight: "auto",
      },
      welcomeContainer: {
        padding: "5px",
      },
      brandStatement: {
        color: primaryColor,
        fontWeight: "lighter",
        fontStyle: "italic",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      delegateHeader: {
        display: "flex",
        justifyContent: "space-between",
        padding: 10,
      },
      delegateContainer: {
        display: "flex",
        justifyContent: "space-between",
      },
      delegateHeaderTitle: {
        color: primaryColor,
        marginTop: "5px",
        marginBottom: "5px",
        marginLeft: theme.spacing(1),
        fontSize: theme.spacing(2.5),
      },
      delegateBusinessUnit: {
        color: primaryColorLight,
        marginLeft: theme.spacing(1),
        marginTop: "5px",
        marginBottom: "5px",
        fontSize: theme.spacing(1.5),
        fontWeight: "bold",
      },
      delegateAvatar: {
        width: 60,
        height: 60,
        marginLeft: theme.spacing(1),
      },
      assessmentTitle: {
        width: "100%",
        textAlign: "center",
      },
      paragraph: {
        textAlign: "justify",
      },
      plcLogo: {
        height: "280px",
        marginLeft: "auto",
        marginRight: "auto",
      },
      stopActivityDescription: {
        color: theme.palette.text.primary,
        padding: theme.spacing(1.5),
      },
      stopBehaviorTextFieldContainer: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
        width: "100%",
      },
      thankYouScreen: {
        padding: "16px",
      },
      thankYouScreenFont: {
        fontFamily: "Roboto",
        fontsize: "10px",
      },
      thankYouScreenFontAway: {
        color: '#8C3038',
      },
      thankYouScreenFontToward: {
        color: '#AFC089',
      },
      textBold: {
        fontWeight: "bold",
      }
    };
  };

  componentDefs: any = {};

  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      step: 0,
      assessment: props.assessment,
      survey: props.survey,
      qualityCustomCommentArray: ["",""],
      qualityCommentLabelsArray: ["",""],
      qualityAction: null,
      showMenu: false,
      showTeamMembers: false,
      showHelp: false,
      feelingRating: 0,
      feelingLabel: '',
      feelingComment: ''
    };
    this.welcomeScreen = this.welcomeScreen.bind(this);
    this.thankYouScreen = this.thankYouScreen.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.ratingScreen = this.ratingScreen.bind(this);
    this.toolbar = this.toolbar.bind(this);
    this.setStep = this.setStep.bind(this);
    this.stopActivities = this.stopActivities.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.assessmentOptions = this.assessmentOptions.bind(this);
    this.onBehaviourRatingChanged = this.onBehaviourRatingChanged.bind(this);
    this.onBehaviourCommentChanged = this.onBehaviourCommentChanged.bind(this);
    this.onNewBehaviour = this.onNewBehaviour.bind(this);
    this.persistRating = this.persistRating.bind(this);
    this.currentStepValid = this.currentStepValid.bind(this);
    this.getDelegateTeamList = this.getDelegateTeamList.bind(this);
    this.componentDefs = this.props.api.getComponents([
      "core.Loading",
      "core.Logo",
      "core.FullScreenModal",
      "core.StaticContent",
      "mores.MoresMyPersonalDemographics",
    ]);
  }
  // #endregion
  componentDidCatch(e) {
    console.error("error defaultview", e);
  }
  is_newer_survey = ()=>{
    const { startDate } = this.state.survey
    return  moment(startDate).isValid() ? (new Date(startDate) >= new Date('2022-12-31T23:59:59')) : false
  }

  isMoresAdmin(){
    return this.props.reactory.hasRole(["ADMIN","DEVELOPER"]) === true
  }
  
  is180(survey) {
    if (!survey) return false;
    if (!survey.surveyType) return false;

    switch (survey.surveyType) {
      case "team180":
      case "180": {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  welcomeScreen() {
    const { classes, assessment, theme, api } = this.props;
    const { componentDefs } = this;
    const { survey } = assessment;

    const is180 = this.is180(survey);
    const isPLC = survey.surveyType === "plc";


    if (!is180) {
      return (
        <Paper className={classes.welcomeContainer}>
          <componentDefs.StaticContent
            slug={`mores-assessments-${survey.surveyType}_${survey.id}-welcome-screen`.toLowerCase()}
            title={`Welcome Screen: ${survey.surveyType}`}
            editAction={"inline"}
            showEditIcon={true}
            defaultValue={
              <Typography gutterBottom variant="body1">
                Thank you for taking the time to complete this{" "}
                {assessment.survey.leadershipBrand.title} survey
              </Typography>
            }
          ></componentDefs.StaticContent>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <componentDefs.StaticContent
              editAction={"inline"}
              slug={`towerstone-CDN-leadershipbrand-main-surveytype_${survey.surveyType}_${survey.leadershipBrand.id}`}
              defaultValue={
                <img
                  src={isPLC ? theme.assets.feplmodel : theme.assets.logo}
                  className={!isPLC ? classes.logo : classes.plcLogo}
                  alt={theme}
                />
              }
            />
          </div>
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.welcomeContainer}>
          <componentDefs.StaticContent
            slug={`mores-assessments-${survey.surveyType}_${survey.id}-welcome-screen`.toLowerCase()}
            title={`Welcome Screen: ${survey.surveyType}`}
            propertyBag={{ survey, assessment }}
            showEditIcon={true}
            editAction={"inline"}
            defaultValue={
              <Typography gutterBottom>
                Thank you for taking the time to assess the{" "}
                {survey.delegateTeam.name} team. This assessment should take
                approximately 5 - 7 minutes.
                <br />
                You will be asked to provide a rating against a series of
                behaviours that are used to measure how we live the
                organisation's leadership brand:
              </Typography>
            }
          ></componentDefs.StaticContent>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <componentDefs.StaticContent
              editAction={"inline"}
              slug={`towerstone-CDN-leadershipbrand-main-surveytype_${survey.surveyType}_${survey.leadershipBrand.id}`}
              defaultValue={
                <img
                  src={isPLC ? theme.assets.feplmodel : theme.assets.logo}
                  className={!isPLC ? classes.logo : classes.plcLogo}
                  alt={theme}
                />
              }
            />
          </div>
        </Paper>
      );
    }
  }
  validateComment = (comment = '') =>{
    const words = comment.split(' ')
    const last_word = words[words.length - 1].trim()
    if(words.length > 3 && comment.trim().length >= 10) return false
    return (comment.trim().length < 10 || words.length < 3 || last_word.length <= 1)
  }
  FeelingRatingScreen = ()=>{
    const { classes, theme, api, reactory} = this.props
    const { StaticContent } = this.componentDefs
    const { feelingRating, feelingComment, survey, assessment } = this.state
    debugger
    const steps = Array.from(Array(10).keys(), x => x + 1)
    const rating_slug = `mores-survey-${survey.id}-assessment_${assessment.id}-assessor_${assessment.assessor.id}-feeling-rating`
    const rating_label_slug = `mores-survey-${survey.id}-feeling-rating-label`
    const default_label = `Please provide reasons for your rating.​`
    const _feelingRating = +feelingRating
    const color = _feelingRating < 5 ? 'red' :  theme.palette.primary.main
    const handleStep = (step: number) => () => {
      this.setState({feelingRating: step})
      this.saveContent({createInput: {
        slug: `${rating_slug}-rating`,
        title: 'feelingRating',
        content: step.toString(),
        updatedAt: new Date().valueOf(),
        published: true,
      }})
    }

    return (
      <Paper className={classes.welcomeContainer}>
        <StaticContent
          slug={`mores-survey-${survey.id}-feeling-rating-prompt`}
          editAction={"inline"}
          showEditIcon={reactory.hasRole(["ADMIN","DEVELOPER"]) === true}
          defaultValue={
            <>
              <Typography
                style={{
                  color: theme.palette.primary.main,
                  textAlign: "center",
                  fontSize: "16px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginBottom: "10px",
                  paddingTop: "2rem",
                }}
              >
               {defaultFeelingRatingLabels(survey.surveyType, assessment.selfAssessment)}<br />
                (1 = poor; 10 = excellent)
              </Typography>
            </>
          }
        />
        <Stepper alternativeLabel={false} nonLinear activeStep={_feelingRating}>
          {steps.map((label, index) => (
            <Step key={label} color={"red"}>
              <StepButton
                onClick={handleStep(label)}
                completed={false}
                active={_feelingRating === label}
              />
            </Step>
          ))}
        </Stepper>
        <Typography
          variant="h4"
          style={{ textAlign: "center", color, padding: "1.5rem 0", fontSize: "18px", }}
        >
          {_feelingRating > 0 && _feelingRating}
        </Typography>

        <Grid container style={{ padding: "10px" }}>
          <Grid item sm={12} lg={12} style={{ marginBottom: "20px" }}>
          <StaticContent
            slug={rating_label_slug}
            editAction={"inline"}
            style={{padding: '0'}}
            showEditIcon={reactory.hasRole(["ADMIN","DEVELOPER"]) === true}
            defaultValue={
              <Typography 
                style={{
                  fontSize: '14px',
                  color: theme.palette.primary.main
                }}>
                {`${ default_label } * [required]`}</Typography>
              }
            />
            <TextField
              multiline
              name={`feeling-comment`}
              key={`feeling-comment-key`}
              InputLabelProps={{ shrink: true }}
              rows={4}
              onBlur={this.updateCustomContent({
                api,
                survey,
                assessment,
                _slug: `${rating_slug}-comment`,
                _title: "feelingComment",
              })}
              onChange={(evt) =>
                this.setState({ feelingComment: evt.target.value })
              }
              fullWidth={true}
              placeholder={'Type your comment here, ...'}
              value={feelingComment}
              variant="outlined"
              error={this.validateComment(feelingComment)}
              helperText={
                <Typography style={{fontSize: '10px'}}>
                  Ensure you add a custom comment that is at least three words
                  with a total of 10 characters
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  thankYouScreen() {
    const { classes, history, api, reactory } = this.props;
    const { assessment, qualityCustomCommentArray, qualityCommentLabelsArray} = this.state;
    const { StaticContent } = this.componentDefs;
    const { survey, assessor: {firstName, lastName} } = assessment;
    const that = this;
    const [comment1, comment2] = qualityCustomCommentArray
    const [label1, label2] = qualityCommentLabelsArray
    const { palette } = api.getTheme();

    const gotoDashboard = () => {
      history.push("/");
    };

    const completeAssessment = () => {
      that.setState({ completing: true }, () => {
        api
          .graphqlMutation(
            gql`
              mutation SetAssessmentComplete($id: String!) {
                setAssessmentComplete(id: $id) {
                  complete
                  updatedAt
                }
              }
            `,
            {
              id: assessment.id,
            }
          )
          .then((response) => {
            let isComplete = false;
            if (
              response.data.setAssessmentComplete &&
              response.data.setAssessmentComplete
            )
              isComplete =
                response.data.setAssessmentComplete.complete === true;
            that.setState(
              {
                completing: false,
                complete: isComplete,
                assessment: {
                  ...lodash.cloneDeep(that.state.assessment),
                  complete: isComplete,
                },
              },
              () => {
                if (that.props.mode !== "admin") {
                  gotoDashboard();
                }
              }
            );
          })
          .catch(() => {
            that.setState({
              completing: false,
              completeError: "Could not update the assessment status",
            });
          });
      });
    };
    const disable_finish = this.validateComment(comment1) || this.validateComment(comment2)
    const styles = {
      marginRight: "4px",
      color: "#ffffff",
      background: palette.success.main,
    }
    const { toward, away } = defaultCommentLabels(survey.surveyType)
    debugger
    return (
      <Paper className={classes.thankYouScreen}>
            <Grid item sm={12} lg={12} style={{ marginBottom: "20px" }}>
            <StaticContent
              slug={`mores-assessments-survey-${survey.id}-comment-explanation`}
              editAction={"inline"}
              showEditIcon={reactory.hasRole(["ADMIN", "DEVELOPER"]) === true}
              defaultValue={
                <>
                  <Typography
                    gutterBottom
                    variant="body1"
                    className={classes.thankYouScreenFont}
                  >
                    In a workplace relationship, certain behaviours from one
                    person activate a threat response and put the other person
                    in an{" "}
                    <span
                      className={`${classes.bold} ${classes.thankYouScreenFontAway}`}
                    >
                      AWAY STATE{" "}
                    </span>
                    <span className={classes.thankYouScreenFontAway}>
                      (leaves them feeling frustrated, disengaged and switched
                      off)
                    </span>
                    . Conversely, other behaviours invoke a reward response
                    placing the other person in a{" "}
                    <span
                      className={`${classes.bold} ${classes.thankYouScreenFontToward}`}
                    >
                      TOWARD STATE{" "}
                    </span>
                    <span className={classes.thankYouScreenFontToward}>
                      (leaves them feeling energised, engaged and switched on)
                    </span>
                    . ​
                  </Typography>
                </>
              }
            />
          </Grid>
        <Grid container style={{ padding: "10px" }}>
          <Grid item sm={12} lg={12} style={{ marginBottom: "20px" }}>
            <StaticContent
              slug={`mores-survey-${survey.id}-customLabel-0`}
              editAction={"inline"}
              showEditIcon={reactory.hasRole(["ADMIN", "DEVELOPER"]) === true}
              defaultValue={
                <Typography
                  variant="body2"
                  color="textSecondary"
                >{`${toward} * [required]`}</Typography>
              }
            />
            <TextField
              multiline
              name={`commentBox_0`}
              key={`commentBox_0`}
              InputLabelProps={{ shrink: true }}
              rows={4}
              onBlur={that.updateCustomContent({ api, survey, assessment })}
              onChange={that.patchCustomContentState({
                qualityCustomCommentArray,
                qualityCommentLabelsArray,
              })}
              fullWidth={true}
              placeholder={label1 || toward}
              value={comment1}
              variant="outlined"
              error={this.validateComment(comment1)}
              helperText="Ensure you add a custom comment that is at least three words with a total of 10 characters"
            />
          </Grid>
          <Grid item sm={12} lg={12} style={{ marginBottom: "20px" }}>
            {this.is_newer_survey() && (
              <>
                <StaticContent
                  slug={`mores-survey-${survey.id}-customLabel-1`}
                  editAction={"inline"}
                  showEditIcon={
                    reactory.hasRole(["ADMIN", "DEVELOPER"]) === true
                  }
                  defaultValue={
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >{`${away} * [required]`}</Typography>
                  }
                />
                <TextField
                  multiline
                  name={`commentBox_1`}
                  key={`commentBox_1`}
                  InputLabelProps={{ shrink: true }}
                  rows={4}
                  onBlur={that.updateCustomContent({ api, survey, assessment })}
                  onChange={that.patchCustomContentState({
                    qualityCustomCommentArray,
                    qualityCommentLabelsArray,
                  })}
                  fullWidth={true}
                  placeholder={label2 || away}
                  value={comment2}
                  variant="outlined"
                  error={this.validateComment(comment2)}
                  helperText="Ensure you add a custom comment that is at least three words with a total of 10 characters"
                />
              </>
            )}
          </Grid>
        </Grid>
        {assessment.complete === false && !disable_finish && (
          <Fragment>
            <StaticContent
              slug={`mores-assessments-survey-${survey.id}-thank-you`}
              editAction={"inline"}
              showEditIcon={reactory.hasRole(["ADMIN", "DEVELOPER"]) === true}
              defaultValue={
                <>
                  <Typography gutterBottom variant="body1">
                    Thank you for taking the time to provide your input. If you
                    are comfortable with the ratings and input that you have
                    provided, please click FINISH.
                  </Typography>
                  <Typography variant="body1">
                    You may click BACK below to review your input, however once
                    you click FINISH you will not be able to change your input.
                  </Typography>
                </>
              }
            />
            <Button
              onClick={() => {
                completeAssessment();
                api.emit("mores_onSurveyAction_complete-assessment", {});
              }}
              style={!disable_finish ? { ...styles } : {}}
              variant="contained"
              disabled={disable_finish}
            >
              Finish
            </Button>
          </Fragment>
        )}
      </Paper>
    );
  }

  startAssessment = () => {
    // SAVES A TIMELINE ENTY ON SURVEY - SURVEY STARTED
    const { api } = this.props;
    const { assessment } = this.state;
    api
      .graphqlMutation(
        gql`
          mutation assessmentStarted($id: String!) {
            assessmentStarted(id: $id)
          }
        `,
        {
          id: assessment.id,
        }
      )
      .then(() => {
        api.log(`ASSESSMENT STARTED TIMELINE SAVED`);
      })
      .catch(() => {
        console.log(`ASSESSMENT STARTED TIMELINE SAVED - ERROR`);
      });
  };

  persistRating(ratingEntry, ratingIndex, deleteRating = false, iteration = 0) {
    const that = this;
    const { api } = this.props;
    const { assessment, assessment_rollback } = this.state;

    api
      .graphqlMutation(
        gql`
          mutation SetRatingForAssessment(
            $id: String
            $ratingId: String
            $rating: Int
            $comment: String
            $qualityId: String
            $behaviourId: String
            $custom: Boolean
            $behaviourText: String
            $deleteRating: Boolean
          ) {
            setRatingForAssessment(
              id: $id
              ratingId: $ratingId
              rating: $rating
              comment: $comment
              qualityId: $qualityId
              behaviourId: $behaviourId
              custom: $custom
              behaviourText: $behaviourText
              deleteRating: $deleteRating
            ) {
              id
              behaviour {
                id
                title
              }
              quality {
                id
                title
              }
              rating
              comment
              custom
              updatedAt
            }
          }
        `,
        {
          id: assessment.id,
          ratingId: ratingEntry.id,
          rating: parseInt(ratingEntry.rating, 10),
          comment: ratingEntry.comment || "",
          qualityId: ratingEntry.quality ? ratingEntry.quality.id : null,
          behaviourId: ratingEntry.behaviour ? ratingEntry.behaviour.id : null,
          custom: ratingEntry.custom === true,
          behaviourText: ratingEntry.behaviourText,
          deleteRating: deleteRating,
        },
        { "fetch-policy": "network-only" }
      )
      .then(({ data, errors = [] }) => {
        if (errors && errors.length > 0) {
          api.createNotification(
            "Could not save your last score. The system may be offline, please try again in a few moments.",
            { showInAppNotification: true, canDismiss: true, type: "errors" }
          );
        }

        if (ratingIndex === -1) {
          const assessmentState = lodash.cloneDeep(assessment);
          assessmentState.ratings.push({
            ...data.setRatingForAssessment,
          });
          that.setState({ assessment: assessmentState });
        }

        if (deleteRating === true) {
          const assessmentState = lodash.cloneDeep(assessment);
          lodash.pullAt(assessmentState.ratings, [ratingIndex]);
          that.setState({ assessment: assessmentState });
        }

        if (iteration > 0) {
          const assessmentState = lodash.cloneDeep(assessment);
          assessmentState.ratings[ratingIndex] = ratingEntry;
          that.setState({ assessment: assessmentState });
        }
      })
      .catch((persistRatingError) => {
        api.log("Error saving rating value", persistRatingError, "error");
        if (assessment_rollback) {
          that.setState({ assessment: assessment_rollback }, () => {
            if (iteration === 0) {
              api.createNotification(
                "Could not save your last score, the system will automatically retry in a few moments.",
                { showInAppNotification: true, canDismiss: true, type: "info" }
              );
              setTimeout(() => {
                that.persistRating(
                  ratingEntry,
                  ratingIndex,
                  deleteRating,
                  iteration + 1
                );
              }, 3000);
            } else {
              if (iteration < 3) {
                setTimeout(() => {
                  that.persistRating(
                    ratingEntry,
                    ratingIndex,
                    deleteRating,
                    iteration + 1
                  );
                }, 3000 * iteration);
              } else {
                api.createNotification(
                  "Could not save your last score, the system may be offline, please try again in a few moments.",
                  {
                    showInAppNotification: true,
                    canDismiss: true,
                    type: "error",
                  }
                );
              }
            }
          });
        }
      });
  }

  onBehaviourRatingChanged(data) {
    const { assessment } = this.state;
    const { behaviour, rating, score } = data;
    const that = this;
    if (lodash.isNil(rating)) return;
    let ratingIndex = lodash.findIndex(assessment.ratings, { id: rating.id });
    if (ratingIndex === -1)
      ratingIndex = lodash.findIndex(assessment.ratings, {
        "behaviour.id": behaviour.id,
      });

    if (ratingIndex >= 0) {
      const ratingEntry = lodash.cloneDeep(assessment.ratings[ratingIndex]);
      ratingEntry.rating = score + 1;
      const assessmentState = lodash.cloneDeep(assessment);
      assessmentState.ratings[ratingIndex] = ratingEntry;
      assessmentState.dirty = true;
      that.setState(
        {
          assessment: assessmentState,
          assessment_rollback: lodash.cloneDeep(assessment),
        },
        () => {
          that.persistRating(ratingEntry, ratingIndex, false, 0);
        }
      );
    }
  }

  onBehaviourCommentChanged(data) {
    const { assessment } = this.state;
    const { behaviour, rating, comment, persist = false } = data;
    const that = this;
    let ratingIndex = lodash.findIndex(assessment.ratings, { id: rating.id });
    if (ratingIndex === -1)
      ratingIndex = lodash.findIndex(assessment.ratings, {
        "behaviour.id": behaviour.id,
      });

    if (ratingIndex >= 0) {
      const ratingEntry = lodash.cloneDeep(assessment.ratings[ratingIndex]);
      ratingEntry.comment = comment;
      const assessmentState = lodash.cloneDeep(assessment);
      assessmentState.ratings[ratingIndex] = ratingEntry;
      assessmentState.dirty = true;
      that.setState({ assessment: assessmentState }, () => {
        if (persist === true) {
          that.persistRating(ratingEntry, ratingIndex);
        }
      });
    } else {
      //console.log('Rating Index not found');
    }
  }

  onNewBehaviour(quality, behaviour) {
    //console.log('Adding a new Quality', { quality, behaviour });
    this.persistRating(
      {
        id: "NEW",
        rating: 0,
        behaviour: {
          id: "CUSTOM",
          title: behaviour,
        },
        behaviourId: "CUSTOM",
        qualityId: quality.id,
        quality: quality,
        behaviourText: behaviour,
        comment: "",
        custom: true,
      },
      -1
    );
  }

  getDelegateTeamList() {
    const { FullScreenModal, StaticContent } = this.componentDefs;
    const { assessment } = this.props;
    const { survey } = assessment;

    const closeDelegateTeamList = () => {
      this.setState({ showTeamMembers: !this.state.showTeamMembers });
    };

    return (
      <FullScreenModal
        open={this.state.showTeamMembers === true}
        onClose={closeDelegateTeamList}
        title={"Team Details"}
      >
        <StaticContent
          slug={`towerstone-team-members-${survey.id}`}
          editAction="link"
        />
      </FullScreenModal>
    );
  }

  updateCustomContent = ({api, survey, assessment, _slug = '', _title = ''}) => (evt: { target: { value: string; name: string; }; }) => {
    let title = _title || `${assessment.assessor.firstName} ${assessment.assessor.lastName} on ${assessment.survey.title}`
    let slug = _slug
    let source = ''
    const {value, name} = evt.target;

    if(!slug){
      const [_source, index] = name.split('_')
      source = _source
      slug = `mores-survey-${survey.id}-customLabel-${index}`
      title = `label ${index} ${assessment.survey.title}`
      if(source === 'commentBox'){
        slug = `mores-survey-${survey.id}-assessment_${assessment.id}-assessor_${assessment.assessor.id}-CustomComment-${index}`
        title = `Comment by ${title}`
      }
    }
    api.log(`Update Content For Custom Comment`, { value }, "debug");
    if(value){
        this.saveContent({createInput: {
          slug,
          title,
          content: value,
          updatedAt: new Date().valueOf(),
          published: true,
      }})
    }
  };
  patchCustomContentState = ({qualityCustomCommentArray, qualityCommentLabelsArray})=> (evt: any) => {
    const {value, name} = evt.target
    const [source, index] = name.split('_')
    const [target_array, key] = source === 'commentBox' 
    ? [qualityCustomCommentArray, 'qualityCustomCommentArray'] : 
      [qualityCommentLabelsArray, 'qualityCommentLabelsArray']

        const new_array = target_array.map((v: any, i: number)=>{
          return parseInt(index) === i ? value : v
        })
        this.setState({ [`${key}`]: new_array });
  };
  getContentByTags = `
      query ReactoryGetContentByMatchedSlug($slug: String!) {
        ReactoryGetContentByMatchedSlug(slug: $slug){
          slug,
          title,
          content
        }
      }
    `
  saveContent = ({createInput})=>{
    const { api } = this.props
      api
        .graphqlMutation(
          gql`
            mutation ReactoryCreateContent(
              $createInput: CreateContentInput!
            ) {
              ReactoryCreateContent(createInput: $createInput) {
                id
                slug
                title
                content
                topics
                published
                createdBy {
                  id
                  fullName
                }
                createdAt
              }
            }
          `,
          {
            createInput
          }
        )
        .then((contentUpdateResult) => {
          api.log(
            `Result from Content Create Update`,
            { contentUpdateResult },
            "debug"
          );
          const { data, errors } = contentUpdateResult;
        })
        .catch((exc) => {
          api.log(`Error Updating Custom Comment `, { exc }, "error");
        });
  }
  loadFeelingContent = ({ source }) => {
    const { assessment, survey, api } = this.props
    const rating_slug = `mores-survey-${survey.id}-assessment_${assessment.id}-assessor_${assessment.assessor.id}-feeling-rating`
    const rating_label_slug = `mores-survey-${survey.id}-feeling-rating-label`

    const slug = source === 'rating' ? rating_slug : rating_label_slug
    api
    .graphqlQuery(this.getContentByTags, { slug })
    .then((result) => {
      const { data, errors } = result;
      api.log(
        `Results from fetching feeling comment/label or rating`,
        { data, errors },
        "debug"
        );
      if(data.ReactoryGetContentByMatchedSlug){
        const _data: {slug: string, content: string, title: string}[] = data.ReactoryGetContentByMatchedSlug
        if(_data.length > 0){
          _data.map(({content, title}) => this.setState({[title]: content}))
        }
      }
      })
      .catch((graphError) => {
        api.log(`Could not load Custom comment`, { graphError }, "error");
      });
  }
  loadCustomQualityComment = ()=> {
    const { api } = this.props;
    const { assessment, qualityCustomComment1 } =
      this.state;
    const { survey } = assessment;
    const labelSlug = `mores-survey-${survey.id}-customLabel`
    const commentSlug = `mores-survey-${survey.id}-assessment_${assessment.id}-assessor_${assessment.assessor.id}-CustomComment`

      const fetch_contents = (slug: string, key: string)=>{
        api
          .graphqlQuery(this.getContentByTags, { slug })
          .then((result) => {
            const { data, errors } = result;
            api.log(
              `Results from fetching custom comment/label`,
              { data, errors },
              "debug"
              );
              
              if (data.ReactoryGetContentByMatchedSlug) {
                const _data = data.ReactoryGetContentByMatchedSlug
                let contents = []
                const {slug, content} = _data[0]
                if(_data.length === 1 && slug && isNaN(slug.slice(-1))){
                  contents.push(content)
                }else{
                  contents = [...this.state[key]]
                  data.ReactoryGetContentByMatchedSlug.map((c: any) => {
                    if(c){
                      const { slug, content} = c
                      contents[parseInt(slug.slice(-1))] = content
                    }
                  })
                }
                this.setState({[`${key}`] : contents})
              }
          })
          .catch((graphError) => {
            api.log(`Could not load Custom comment`, { graphError }, "error");
            this.setState({ qualityCustomComment1, qualityAction: "" });
          });
      }
  
      fetch_contents(commentSlug, 'qualityCustomCommentArray')
      fetch_contents(labelSlug, 'qualityCommentLabelsArray')
  }

  ratingScreen() {
    const that = this;
    const { classes, api } = this.props;
    const {
      step,
      assessment,
      newBehaviourText,
      qualityCustomCommentArray,
      qualityCommentLabelsArray
    } = this.state;
    const { delegate, ratings, survey } = assessment;
    const { StaticContent } = this.componentDefs;
    const quality = assessment.survey.leadershipBrand.qualities[step - 2];
    const { slugify } = api.utils;

    const behaviours = quality.behaviours.map((behaviour) => {
      let ratingIndex = lodash.findIndex(ratings, (r: any) => {
        return behaviour.id === r.behaviour.id && quality.id === r.quality.id;
      });
      let rating = assessment.ratings[ratingIndex];

      if (!rating) {
        //console.log(`No rating object for quality: ${quality.id}, ${behaviour.id}`);
        rating = {
          rating: 0,
          comment: "",
          id: null,
          custom: false,
          behaviourText: null,
        };
      }

      const commentBlurred = () => {
        that.persistRating(rating, ratingIndex);
      };

      return (
        <RatingComponent
          key={`default.${ratingIndex}`}
          behaviour={{
            ...behaviour,
            qualityId: quality.id,
            quality,
            scale: assessment.survey.leadershipBrand.scale,
          }}
          rating={rating}
          comment={rating.comment}
          assessment={assessment}
          ratingIndex={ratingIndex}
          onRatingChange={this.onBehaviourRatingChanged}
          onCommentChange={this.onBehaviourCommentChanged}
          onBlur={commentBlurred}
        />
      );
    });

    const customBehaviours = lodash
      .filter(ratings, (r) => {
        return r.quality.id === quality.id && r.custom === true;
      })
      .map((rating) => {
        const ratingIndex = lodash.findIndex(
          ratings,
          (r: any) => r.id === rating.id
        );

        const commentBlurred = () => {
          that.persistRating(rating, ratingIndex);
        };

        const deleteCustomRating = () => {
          that.persistRating(rating, ratingIndex, true);
        };

        return (
          <RatingComponent
            key={`custom.${ratingIndex}`}
            behaviour={{
              ...rating.behaviour,
              qualityId: rating.quality.id,
              quality: rating.quality,
              scale: assessment.survey.leadershipBrand.scale,
            }}
            rating={rating}
            comment={rating.comment}
            assessment={assessment}
            ratingIndex={ratingIndex}
            onRatingChange={this.onBehaviourRatingChanged}
            onCommentChange={this.onBehaviourCommentChanged}
            onDelete={deleteCustomRating}
            onBlur={commentBlurred}
          />
        );
      });

    const setNewBehaviourText = (evt) => {
      that.setState({ newBehaviourText: evt.target.value });
    };

    const onNewBehaviourClicked = () => {
      const newBehaviourTextToCreate = newBehaviourText;
      that.setState({ newBehaviourText: "" }, () => {
        that.onNewBehaviour(quality, newBehaviourTextToCreate);
      });
    };

    const toggleShowTeam = () => {
      that.setState({ showTeamMembers: !that.state.showTeamMembers });
    };

    const onClearCustomText = () => that.setState({ newBehaviourText: "" });

    let CustomFeedbackComponent = (
      <Grid item sm={12} xs={12}>
        <Paper style={{ padding: "5px" }}>
          <Typography>
            If you want to provide a customised behaviour that{" "}
            {assessment.survey.surveyType === "180"
              ? `the ${assessment.survey.delegateTeam.name} team`
              : delegate.firstName}{" "}
            exhibits that relates to {quality.title}, type it in the box below
            and then click the add <Icon>add</Icon> button and provide your
            rating and feedback.
            <br />
            <br />
            Note, these custom ratings will not affect the calculation of{" "}
            {assessment.survey.surveyType === "180"
              ? `the ${assessment.survey.delegateTeam.name} team`
              : delegate.firstName}
            's overall rating for this assessment.
          </Typography>
          <Paper className={classes.root} elevation={1}>
            <InputBase
              className={classes.input}
              placeholder={"Provide a custom behaviour description"}
              onChange={setNewBehaviourText}
              fullWidth={true}
              value={newBehaviourText}
            />
            <IconButton
              onClick={onNewBehaviourClicked}
              className={classes.iconButton}
              aria-label="Search"
            >
              <Icon>add</Icon>
            </IconButton>
            <Divider className={classes.divider} />
            <IconButton
              onClick={onClearCustomText}
              color="primary"
              className={classes.iconButton}
              aria-label="Directions"
            >
              <Icon>close</Icon>
            </IconButton>
          </Paper>
        </Paper>
      </Grid>
    );

    let includeAdminComment = false;

    switch (assessment.survey.surveyType) {
      case "i360":
      case "l360":
      case "culture":
      case "team180": {
        includeAdminComment = true && this.props.mode === "admin";

        const [comment1, comment2] = qualityCustomCommentArray
        const [label1, label2] = qualityCommentLabelsArray
        const default_label = `Provide a custom comment for: ${quality.title}`

        /**
         * A component that displays a comment box along with a textfield that allows
         * ivolve admin to change the prompt/label for each question
         * @param label 
         * @param index 
         * @param comment 
         * @returns 
         */
        CustomFeedbackComponent = null
      }
    }
    const { title: _title_, description } = getInstructions(quality.title);

    const _styles: any = {
      scale: {
        marginLeft: "5px",
        marginRight: "2px",
        fontWeight: "bold",
      },
      scaleWrapper: {
        padding: "15px 0",
        display: "block",
      },
      captionText: {
        fontSize: "14px",
      },
    };
    const assessmetnInstructionsDefaultContent = (
      <>
        <Typography
          variant="caption"
          color="primary"
          style={_styles.captionText}
        >
          <span style={{ fontWeight: "bold" }}>{_title_}:</span>
          <span> This section tests for {description}</span>
        </Typography>
        <Typography
          style={{ ..._styles.scaleWrapper, ..._styles.captionText }}
          variant="caption"
          color="primary"
        >
          <span style={{ fontWeight: "bold" }}>Rating scale: </span>
          <span style={_styles.scale}> 1. </span>Never
          <span style={_styles.scale}> 2. </span>Seldom
          <span style={_styles.scale}> 3. </span>Sometimes
          <span style={_styles.scale}> 4. </span>Often
          <span style={_styles.scale}> 5. </span>Always
        </Typography>
        <Typography
          variant="caption"
          style={{ ..._styles.captionText, color: "red" }}
        >
          Two comment boxes are provided at the end of the survey for additional
          feedback.
        </Typography>
      </>
    );

    const contentId = `mores-assessments-instructions-${slugify(
      quality.id
    )}-survey-${survey.id}`;

    return (
      <Grid container spacing={8}>
        <Grid item sm={12} xs={12}>
          <StaticContent
            id={contentId}
            slug={contentId}
            title={`Mores Assessment Survey Instruction Header - ${survey.title} [${quality.title}]`}
            defaultValue={assessmetnInstructionsDefaultContent}
            viewMode="default"
            editAction="inline"
          />
          {this.is180(assessment.survey) === true ? (
            <Typography variant="caption" color="primary">
              &nbsp;Provide ratings in context of the entire team{" "}
              <IconButton onClick={toggleShowTeam}>
                <Icon>supervised_user_circle</Icon>
              </IconButton>
            </Typography>
          ) : null}
          {this.is180(assessment.survey) === true
            ? this.getDelegateTeamList()
            : null}
          {behaviours}
          {customBehaviours.length > 0 ? (
            <Fragment>
              <hr
                style={{
                  marginBottom: `${this.props.theme.spacing(1)}px`,
                  marginTop: `${this.props.theme.spacing(1)}px`,
                }}
              />
              <Typography variant="caption" color="primary">
                Custom Behaviours for {quality.title} added by you
              </Typography>
              {customBehaviours}
            </Fragment>
          ) : null}
        </Grid>
        {CustomFeedbackComponent && (
          <Grid item sm={12} xs={12}>
            {CustomFeedbackComponent}
          </Grid>
        )}
      </Grid>
    );
  }

  prevStep() {
    const that = this;
    if (that.state.step > 0) {
      const nextStepIndex = this.state.step - 1;
      this.setState({ step: nextStepIndex }, ()=>{
        if(nextStepIndex === 1){
          this.loadFeelingContent({ source: 'rating' })
          this.loadFeelingContent({ source: 'label' })
        }
      })
    }
  }
  
  nextStep() {
    let maxSteps = this.props.assessment.survey.leadershipBrand.qualities.length + 3;
    if (this.state.step < maxSteps) {
      if (this.state.step == 0) this.startAssessment(); // TIMELINE ENTRY - ASSESSMENT STARTED
      const nextStepIndex = this.state.step + 1;
      this.setState({ step: nextStepIndex }, () => {
        window.scrollTo({ top: 0 });
        if (nextStepIndex === maxSteps - 2) {
          this.loadCustomQualityComment();
        }
        if(nextStepIndex === 1){
          this.loadFeelingContent({ source: 'rating' })
          this.loadFeelingContent({ source: 'label' })
        }
      });
    }
  }

  setStep(event, step) {
    //console.log('Step change', step);
    this.setState({ step });
  }

  toolbar(content) {
    const alphaindex = "A,B,C,D,E,F,G,H,I,J,K,L".split(",");

    let tabs = [<Tab key={"w"} label="Welcome" />, <Tab key={"initial-rating"} label="Personal Rating"/>];
    this.props.assessment.survey.leadershipBrand.qualities.map(
      (quality, kidx) =>
        tabs.push(
          <Tab
            key={kidx}
            label={`${alphaindex[kidx]}. ${quality.title}`}
            style={{ cursor: "default" }}
          />
        )
    );
    tabs.push(<Tab key={"c"} label="Complete" />);
    return (
      <AppBar position="static" color="default">
        <Tabs
          value={this.state.step}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs}
        </Tabs>
      </AppBar>
    );
  }

  stopActivities() {
    const { classes } = this.props;
    return (
      <Grid container spacing={8}>
        <Grid item sm={12} xs={12}>
          <Paper>
            <Typography>
              This section is used to add any STOP behaviours
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  assessmentOptions() {
    const { assessment } = this.state;
    const cancelAssessment = () => {
      this.props.history.push("/");
    };

    const saveAndCloseAssessment = () => {
      this.props.history.push("/survey");
    };

    const viewReport = () =>
      this.props.history.push(`/report/${assessment._id}`);

    const options = (props) => (
      <Menu
        open={props.open}
        id="assessment-options"
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={props.cancelClicked}>
          <ListItemIcon>
            <CancelIcon />
          </ListItemIcon>
          Close
        </MenuItem>
      </Menu>
    );

    options.muiName = "IconMenu";

    return options({
      open: this.state.showMenu,
      anchorEl: this.state.anchorEl,
      cancelClicked: cancelAssessment,
      saveAndCloseAssessment: saveAndCloseAssessment,
      viewReport,
    });
  }

  handleMenu(evt) {
    this.setState({
      showMenu: !this.state.showMenu,
      anchorEl: evt.currentTarget,
    });
  }

  currentStepValid = ()=> {
    const { mode } = this.props;
    const { assessment, step, feelingComment } = this.state;

    if (mode === "admin") return true;

    if (step === 0) return true;

    let maxSteps = assessment.survey.leadershipBrand.qualities.length + 3;
    if (step === maxSteps - 1) return false;
    if(step === 1) {
      return !this.validateComment(feelingComment)
    }
    const { ratings } = assessment;
    const quality = assessment.survey.leadershipBrand.qualities[step - 2];

    const invalidRatings =
      lodash.find(ratings, (r) => {
        let commentRequired = true;

        switch (assessment.survey.surveyType) {
          case "i360":
          case "l360":
          case "culture":
          case "team180": {
            commentRequired = false;
            break;
          }
        }

        if (commentRequired === true)
          return (
            r.quality.id === quality.id &&
            r.rating <= 2 &&
            (lodash.isEmpty(r.comment) === true || r.comment.length < 50)
          );
        return r.quality.id === quality.id && r.rating === 0;
      }) || [];

    if (invalidRatings.length === 0) return true;
    else return false;
  }

  render() {
    const { classes, theme, api } = this.props;
    const { step, assessment, showMenu } = this.state;
    const { delegate, survey, selfAssessment } = assessment;
    const { nextStep, toolbar, assessmentOptions, handleMenu, prevStep } = this;
    let wizardControl = null;
    let maxSteps = assessment.survey.leadershipBrand.qualities.length + 3;
    const isCurrentStepValid = this.currentStepValid();
    if (step === 0) wizardControl = this.welcomeScreen();
    if (step === 1) wizardControl = this.FeelingRatingScreen()
    if (step === maxSteps - 1) wizardControl = this.thankYouScreen();
    if (nil(wizardControl) === true) wizardControl = this.ratingScreen();


    const is180 = this.is180(survey);

    let headerTitle = assessment.delegate
      ? `${api.getUserFullName(delegate)} - ${survey.title} ${
          selfAssessment === true ? " [Self Assessment]" : ""
        }`
      : `Unknown`;
    if (is180 === true) {
      headerTitle = `${survey.title}`;
    }

    if (survey.surveyType === "culture") {
      headerTitle = `${survey.title}`;
    }

    const isThankYou = step === maxSteps - 1;
    const { palette } = theme;

    const nextButtonStyle = {
      // backgroundColor: (step > 0 && isCurrentStepValid === false && isThankYou === false) ? 'unset' : palette.secondary.main,
    };


    let nextText = "NEXT";
    let showNext = true;
    let nextIcon = <Icon>keyboard_arrow_right</Icon>;

    if (step > 0 && isCurrentStepValid === false && isThankYou === false) {
      nextText = "INCOMPLETE";
      nextIcon = <Icon>priority_high</Icon>;
    }

    if (step > 0 && isThankYou) {
      nextText = "DONE";
      showNext = false;
      nextIcon = <Icon>block</Icon>;
    }

    let nextButton = (
      <Tooltip
        title={
          isCurrentStepValid
            ? "Click to proceed to the next section"
            : "Complete all ratings and comments in full before proceeeding."
        }
      >
        <Button
          variant={"contained"}
          size="small"
          color="secondary"
          onClick={nextStep}
          disabled={isCurrentStepValid === false || isThankYou === true}
          style={nextButtonStyle}
        >
          {nextText}
          {nextIcon}
        </Button>
      </Tooltip>
    );

    return (
      <Grid container spacing={1} className={classes.card}>
        <Grid item xs={12} sm={12}>
          <Paper>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12}>
                <CardHeader
                  avatar={
                    <Avatar
                      src={api.getAvatar(delegate)}
                      className={classNames(classes.delegateAvatar)}
                      alt={assessment.delegateTitle}
                    ></Avatar>
                  }
                  title={headerTitle}
                  subheader={`Survey valid from ${moment(
                    survey.startDate
                  ).format("DD MMMM YYYY")} till ${moment(
                    survey.endDate
                  ).format("DD MMMM YYYY")} - ${
                    assessment.complete === true
                      ? "Completed - Review Only"
                      : "In progress"
                  }`}
                  action={
                    <IconButton
                      aria-owns={showMenu ? "assessment-options" : null}
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                      <MoreVertIcon />
                      {assessmentOptions()}
                    </IconButton>
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          {toolbar(null)}
        </Grid>
        <Grid item xs={12} sm={12} style={{ marginBottom: "48px" }}>
          {wizardControl}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MobileStepper
            variant="dots"
            steps={maxSteps}
            position="bottom"
            activeStep={step}
            nextButton={nextButton}
            backButton={
              <Button
                variant="outlined"
                size="small"
                onClick={prevStep}
                disabled={step === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Grid>
      </Grid>
    );
  }

  static propTypes = {
    assessment: PropTypes.object,
    api: PropTypes.instanceOf(ReactoryApi),
    mode: PropTypes.oneOf(["assessor", "delegate", "admin"]),
  };

  static defaultProps = {
    assessment: new Assessment(),
    mode: "assessor",
  };
}

const DefaultViewComponent = compose(
  withApi,
  withRouter,
  //@ts-ignore
  withStyles(DefaultView.styles),
  withTheme
)(DefaultView);

export default DefaultViewComponent;
